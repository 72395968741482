import './contentf1.scss';
import contentImg from '../../../img/page-content-4/ct4.jpg';
import contentImgMob from '../../../img/page-content-4/ct4-mob.jpg';

const Contentf1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentf1');
 
	contentElement.innerHTML = `
	<div class='contentf1__container'>
	
	<div class="contentf1__body">
	<h2 class="contentf1__title">

	Gemini Grand – платформа аферистов или лучший способ для заработка

	</h2>
		<p class="contentf1__text">
		Клиенты оставляют в интернете только положительные отзывы об инвестициях Gemini Grand. Трейдеры, которые попробовали торговать на автоматической платформе, отмечают ее практичность и высокую доходность. На сайте опубликованы Gemini Grand контакты для связи с техподдержкой и ссылки на соцсети компании. Пользователи могут связаться с суппортом и получить качественную поддержку, при возникновении каких-либо вопросов по настройке платформы. Компания официально зарегистрирована в США и получила лицензию на проведение операций с финансами клиентов. Она прошла проверку на соответствие антимонопольным стандартам и контроль на отмывку денег. Данные клиентов защищены многоэтапным шифрованием и не доступны различным мошенникам.
		</p>
        <p class="contentf1__text">
		Работает компания на законных основаниях, поэтому клиенты могут не сомневаться в том, что их личные данные и денежные средства будут в надежных руках, и они могут всегда рассчитывать на помощь профессионалов. Как начать торговать на платформе Гемини Гранд? Работа на платформе безопасна и прибыльна, поэтому можно смело проходить Gemini Grand регистрацию. Она не занимает много времени. Для начала пользователю понадобится заполнить форму, куда вносятся персональные данные а также номер телефона, адрес электронной почты. После этого на связь с клиентом выходит менеджер компании и помогает сделать первый депозит. В случае необходимости он подсказывает как настроить площадку для получения наилучшего результата.

		</p>
        <p class="contentf1__text">

		Вход в Gemini Grand находится на сайте. Для того чтобы авторизоваться, нужно ввести свои регистрационные данные – электронную почту и пароль. После этого клиенту открывается доступ в личный кабинет, где находится весь функционал платформы и торговые инструменты. Все это создано для инвесторов и трейдеров, чтобы они могли зарабатывать в комфортных условиях, используя автоматизированную систему. Зарабатывать на платформе могут и новички, и профессионалы. Для этого не требуется опыт в торговле или обучение. Пользователям не нужно проводить анализ рынка, мониторить финансовые новости или торговать на бирже. Все это делает за них программное обеспечение.
			
		</p>

        
	</div>
		
    <img class="contentf1__img" width="1180" height="650" src="${contentImg}">
	<img class="contentf1__img-mob" width="432" height="210" src="${contentImgMob}">
	
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentf1;