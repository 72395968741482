import './content4.scss';
import contentImg3 from '../../../img/page-contant/content4/ct1111.jpg';
import contentImg3mob from '../../../img/page-contant/content4/ct1111-mob.jpg'

const Content4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content4');
 
	contentElement.innerHTML = `
	<div class='content4__container'>
	<img class="content4__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content4__img" width="1180" height="310" src="${contentImg3}">
	
	<div class="content4__body">
	<h2 class="content4__title">

	Преимущества платформы перед ее конкурентами
	</h2>
		<p class="content4__text">
		Сейчас клиенты платформы живут в 70 странах мира. Прозрачность и легальность платформы привлекает огромное количество новых клиентов со всего мира. При этом заработок на ней проходит автоматически без участия человека. Что такое Gemini Grand и как заработать на этой площадке? Начиная с 2014 года компания занимается исключительно криптовалютами и операциями с ними. Поэтому она имеет обширный опыт в сфере инвестирования и торговли.
		
		</p>
	<p class="content4__text">
	Основное отличие платформы от других подобных сервисов заключается в том, что она регулируется в Америке и имеет лицензии на проведение финансовых операций. Кроме того, она получила свидетельство о соответствии критериям антимонопольного законодательства (AML) .Программа для заработка в Gemini Grand – это новая идея разработчиков компании. Программное обеспечение имеет массу преимуществ - проводит анализ ситуации на рынке и принимает решение о торговых сделках, создает долгосрочные и кратковременные депозиты, операции с ордерами происходят мгновенно, хорошо работает техподдержка, развивается и создает собственные продукты, создает условия для диверсификации активов с целью уменьшения рисков.
	
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content4;