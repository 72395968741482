import './header1.scss';
import logo from '../../../img/logo.png';
const Header1 = () => {

	
	  
	const heroElement = document.createElement('header');
	heroElement.classList.add('header', 'header-1');
 
	heroElement.innerHTML = `
	<div class='header__container'>
		 <img class='header__logo' alt="logo/png" width="174" height="50" src=${logo}>
	  <ul class='header__nav nav'>
	  <li><a class='nav__link' href='#platform' data-target='.content4__title'> Платформа </a></li> 
	  <li><a class='nav__link' href='#advantages' data-target='.contents3__title'> Инвестиции </a></li> 
	  <li><a class='nav__link' href='#idea' data-target='.contentf1__title'>Доход</a></li> 
	  <li><a class='nav__button' href='#' id='popupBtn'>Оставить заявку</a></li> 
	  </ul>
	  <div class="header__burger burger">
	 	<span class="burger__line"></span>
		<span class="burger__line"></span>
		<span class="burger__line"></span>
	  </div>
	</div>
	
	`;

	
	
	return heroElement;
 };


 export default Header1;