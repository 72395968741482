import './contentt2.scss';
import contentImg from '../../../img/page-content-3/ct33.jpg';
import contentImgMob from '../../../img/page-content-3/ct33-mob.jpg';

const Contentt2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt2');
 
	contentElement.innerHTML = `
	<div class='contentt2__container'>
	
	
	<div class="contentt2__body">
	<h2 class="contentt2__title">

	Гарантированный заработок

	</h2>
		<p class="contentt2__text">
		Платформа для заработка Gemini Grand получает доход на любых движениях цен и в любое время суток, что невозможно для человека. Для того чтобы получать максимальный результат, программа использует искусственный интеллект и новые технологии. Она работает по устойчивому алгоритму - торговые операции по математическим расчетам, проводит анализ происходящих событий и делает точный прогноз, оптимизирует различные активы в инвестиционном портфеле клиентов, предлагает новые пути решения и победные стратегии, постоянно обновляется и самосовершенствуется.

		</p>
<img class="contentt2__img-mob" width="432" height="210" src="${contentImgMob}">
	
	</div>
		

	<img class="contentt2__img" width="600" height="503" src="${contentImg}">
	</div>
	<div class="contentt2__container">
		<p class="contentt2__text">
		Клиентам доступно страхование инвестиционных инструментов с целью защиты их от посягательств хакеров и мошенников. Еще одно важное преимущество заключается в том, что инвестиции в Gemini Grand генерируют доход от стейкинга различных криптовалют. Платформа в автоматическом режиме разрабатывает эффективные торговые стратегии, на основе анализа большого количества данных. Почему платформа Гемини Гранд – лучший выбор для инвестиций? Уникальное программное обеспечение платформы объединило в себе обширную стратегию и неограниченные возможности искусственного интеллекта. Оно не допускает потери средств, которые возможны при обычной торговле.

		</p>

		<p class="contentt2__text">
		Программа сама принимает решения о том, какую операцию проводить в данный момент времени. Программное обеспечение настроено для работы по строгому плану - анализ полученной информации, выбор конкурентоспособной стратегии, принятие единственно правильного решения, заключение удачных сделок, анализ пройденного этапа. Программное обеспечение Gemini Grand инвест постоянно мониторит изменение цен, тенденции рынка, экономические новости, поэтому все сделки приносят позитивные результаты и значительно увеличивают доход акционеров. Клиенты могут быть уверены в своих результатах, так как платформа сводит их потери к минимальному уровню, применяет разнообразные стратегии и методы трейдинга, а что самое главное – проводит все операции в автоматическом режиме.	

		</p>
		<p class="contentt2__text">
		Зарабатывать на платформе можно как с десктопной версии, так и с помощью приложения Gemini Grand. Это особенно приятно для любителей путешествий, которые предпочитают работать на ходу. Тем более, что это не отнимает много времени. На официальном сайте Gemini Grand клиенты получают всю необходимую информацию о компании и о том, как зарабатывать на инвестициях в криптовалюту. Они при помощи приложения могут контролировать торговый терминал.
 <br>На вооружении площадки находятся около 80 токенов и стейблкоинов. Автоматическая торговля этими активами – отличное решение для начинающих трейдеров. По их отзывам о Gemini Grand invest программа компании приносит хороший доход и помогает сохранить и приумножить свои сбережения. При этом робот торгует 24/7, а прибыль можно легко вывести на любой криптокошелек. Программа выбирает наиболее активные валютные пары и совершает прибыльные торговые сделки без риска потери денег.
		</p>
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentt2;