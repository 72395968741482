import './hero1.scss';
import heroImg from '../../../img/hero-1.jpg';
import heroMobImg from '../../../img/hero-1-mob.jpg';
const Hero1 = () => {
	const heroElement = document.createElement('div');
	heroElement.classList.add('hero1');
 
	heroElement.innerHTML = `
	<div class='hero1__container'>
		<div class="hero1__contant">
	<img class="hero1__mob-img" width="537" height="503" src="${heroMobImg}" alt="img/jpg">

	<h1 class="hero1__title1">

	Как зарабатывать с космической скоростью в компании Space X

	</h1>
	<p class="hero1__text">
	Знаменитый изобретатель и миллиардер Илон Маск создал компанию по производству летательных аппаратов для полетов в космос.
	
	</p>

	<form method="POST" action="#" autocomplete="off" class="lead-form consult-form" data-redirect="true"
		data-redirect-url="thank-you.html">
		<input type="text" name="name" id="name" placeholder=" Имя" required>
		<input type="email" name="email" id="email" placeholder="Email" required>
		<input type="tel" name="phone" id="phone" style="z-index:99;" placeholder="Телефон" required>
		<button class="consult-btn" type="submit">Оставить заявку</button>
		<ul style="color:red; position:relative; z-index:10;" class="messages"></ul>
	</form>
</div>
		 <img class='hero1__img' src=${heroImg}>

	</div>
	
	`;
 
	return heroElement;
 };
 
 export default Hero1;