import './hero3.scss';

const Hero3 = () => {
	const heroElement = document.createElement('div');
	heroElement.classList.add('hero3');
 
	heroElement.innerHTML = `
	<div class='hero3__container'>
		<div class="hero3__contant">
	<h3 class="hero3__subtitle">

	
	</h3>
	<h1 class="hero3__title">

	Платформа Gemini Grand – ваш надежный партнер в криптовалютной индустрии

	</h1>
	<p class="hero3__text">
	Американская платформа Gemini Grand была основана в 1994 году. Тогда никто не мог предположить, что она станет одной из самых надежных и популярных криптобирж.
	</p>

	<form method="POST" action="#" autocomplete="off" class="lead-form consult-form" data-redirect="true"
		data-redirect-url="thank-you.html">
		<input type="text" name="name" id="name" placeholder="Ваше Имя" required>
		<input type="email" name="email" id="email" placeholder=" Ваша почта" required>
		<input type="tel" name="phone" id="phone" style="z-index:99;" placeholder="Телефон" required>
		<button class="consult-btn" type="submit">Отправить</button>
		<ul style="color:red; position:relative; z-index:10;" class="messages"></ul>
	</form>
</div>
	

	</div>
	
	`;
 
	return heroElement;
 };
 
 export default Hero3;