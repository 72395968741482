import './contents2.scss';
import contentImg3 from '../../../img/page-contant-2/content2-2/ct22.jpg';
import contentImg3mob from '../../../img/page-contant-2/content2-2/ct22-mob.jpg'

const Contents2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents2');
 
	contentElement.innerHTML = `
	<div class='contents2__container'>
	<img class="contents2__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="contents2__img" width="1180" height="400" src="${contentImg3}">
	
	<div class="contents2__body">
	<h2 class="contents2__title">
	Особенности автоматической торговли на платформе Space X
	</h2>
		<p class="contents2__text">
		На платформе нужно сделать пополнение баланса в личном кабинете Space X. Автоматическую торговлю акциями и другими активами можно запустить в этот же день. Программное обеспечение вместо вас начнет работать на фондовом рынке. Оно будет проводить анализ информации, новостей и цен и на основе полученных данных создавать прибыльные сделки. Перед пользователями открываются широкие возможности для получения пассивного быстро растущего дохода. Это приведет к росту личного капитала и даст возможность создавать новые инвестиционные проекты. <br>
		Все инструменты этой программы доступны после Space X регистрации и пополнения личного счета. ПО использует ваши финансовые возможности для совершения прибыльных сделок и проведения операций. С помощью площадки, работать на которой можно с любого гаджета, персонального компьютера или ноутбука, вы сможете эффективно управлять своими финансами. Трейдер может спокойно отдыхать, пока ПО работает и совершает торговые операции, а потом выводить заработанные деньги.

		</p>
	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents2;